import iso3ToIso2 from '~/constants/countries/countries-iso3-to-iso2.json'
import enUS from '~/constants/countries/alphacode-countries-en_US.json'
import frFR from '~/constants/countries/alphacode-countries-fr_FR.json'

const getCountryName = (alphaCode, locale) => {
  const alpha2code = alphaCode in iso3ToIso2 ? iso3ToIso2[alphaCode] : alphaCode
  if (locale === 'en' && alpha2code in enUS) {
    return enUS[alpha2code]
  }
  if (locale === 'fr' && alpha2code in frFR) {
    return frFR[alpha2code]
  }
  return null
}

export default getCountryName
